<template>
  <v-row no-gutters>
    <v-col>
      <h4 style="font-family: Noto Sans Sinhala Light">
        <span v-if="icon == 'mdi-arrow-left'">
          <v-icon @click="$router.go(-1)" large>{{icon}}</v-icon>
        </span>
        <span v-else>
          <v-icon large class="pb-1">{{icon}}</v-icon>
        </span>
        {{title}}
      </h4>
      <v-divider class="mt-0"></v-divider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DashboardHeader",
  props: {
    title: String,
    icon: String
  }
};
</script>

<style>
</style>