<template>
  <div>
    <v-card class="gradient custom-shadow" dark>
      <v-card-title class="justify-center" style="background-color: #e464b7">{{name}}</v-card-title>
      <v-list-item>
        <v-list-item-content>
          <div>
            <v-list-item-title class="text-center">
              <span style="font-size: 1.75rem">රුපි. {{price}}</span>
              <span>/{{periodInMonths}}</span>
            </v-list-item-title>
            <v-divider class="mt-3"></v-divider>
          </div>
          <p :key="feature.featureId" class="body-2 my-2" v-for="feature in features">
            <v-icon
              :color="feature.limit != 0 ? '': '#fb7f76'"
              class="mr-2"
            >{{feature.limit != 0 ? 'mdi-check-circle': 'mdi-close-circle'}}</v-icon>
            <router-link
              style="font-size: small; color: white"
              to="horoscope-match"
              v-if="feature.feature.id == 7"
            >{{feature.feature.name}}</router-link>
            <span v-else>{{feature.feature.name}}</span>
            <span v-if="feature.limit > 1">({{feature.limit}})</span>
          </p>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-dialog max-width="690" v-model="dialog">
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="isDisabled"
              block
              color="accent"
              depressed
              v-on="on"
            >{{subscribeBtnText}}</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span
                :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1.1rem': ''"
              >පැකේජය ලබාගන්න ආකාරය</span>
            </v-card-title>
            <v-divider class="mt-0 mb-4"></v-divider>
            <v-card-text class="font-weight-bold">
              <p>පැකේජයට අදාල වටිනාකම පහත බැංකු විස්තරයට අනුව ආයතනයේ ගිණුමට බැර කරන්න.</p>
              <p class="mb-1">බැංකුව - ලංකා බැංකුව</p>
              <p class="mb-1">ගිණුමේ නම - ආශීර්වාද මංගල යෝජනා ආයතනය</p>
              <p class="mb-1">ශාඛාව - කඩවත</p>
              <p>ගිණුම් අංකය - 85207981</p>
              <p>
                මුදල් බැර කිරීමෙන් ලැබෙන රිසිට් පතෙහි ඔබගේ සාමජික අංකය හා ජාතික හැඳුනුම්පත් අංකය සටහන්
                කර පහත එක් ආකාරයකට අප වෙත ලැබීමට සැලැස්වීමෙන් මෙම පැකේජය සක්‍රීය කරගත හැක.
              </p>
              <p>
                අදාල රිසිට්පත 0717928628 අංකයට Whatsapp/Viber/Imo යන මාර්ගයකින් අප වෙත එවා 0717928627 අංකයට
                ඇමතුමක් ලබාදීම මගින් හෝ ashirwadaproposals@gmail.com වෙත එවා 0717928627 අංකය වෙත ඇමතුමක් ලබාදීම
                මගින් පහසුවෙන්ම මෙම පැකේජයට අදාල සේවාවන් ලබාගත හැක.
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['id', 'name', 'price', 'period', 'features', 'currentPackage'],

  data: () => ({
    loading: false,
    dialog: false
  }),

  computed: {
    isDisabled: function () {
      if (this.currentPackage == this.id) {
        return true;
      } else {
        return false;
      }
    },

    subscribeBtnText: function () {
      if (this.currentPackage == this.id) {
        return 'සක්‍රීයයි';
      } else {
        return 'සක්‍රිය කරන්න';
      }
    },

    periodInMonths: function () {
      if (this.period == 0) {
        return "අසීමිතයි";
      } else {
        return "මාස " + this.period;
      }
    }
  }
}
</script>

<style scoped>
.gradient {
  background-image: radial-gradient(
    circle 997px at 5.8% 11.8%,
    #0277bd,
    #2196f3
  );
}
</style>