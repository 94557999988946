<template>
  <v-container>
    <DashboardHeader icon="mdi-bag-personal" title="පැකේජයන්" />
    <v-row class="mt-n3">
      <v-col :key="pkg.id" cols="12" md="4" sm="6" v-for="pkg in packages">
        <PackageItem
          :currentPackage="currentPackageId"
          :features="pkg.package_features"
          :id="pkg.id"
          :name="pkg.name"
          :period="pkg.periodInMonths"
          :price="pkg.price"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { ApiService } from "../services/api.service";
import { TokenService } from "../services/storage.service";
import { CHANGE_LOADING_STATE } from "../store/mutation-types";
import DashboardHeader from "../components/DashboardHeader";
import PackageItem from "../components/PackageItem";

export default {
  name: "package",
  components: {
    DashboardHeader,
    PackageItem
  },
  data: () => ({
    packages: [],
    currentPackageId: null
  }),

  mounted: async function () {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchPackages();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  methods: {
    // Fetch  all the active packages
    fetchPackages: async function () {
      try {
        const url = 'packages';
        const params = { params: { active: 1 } };
        const response = await ApiService.get(url, params);
        this.packages = response.data.packages;
        this.currentPackageId = response.data.current;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>